import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import img from '../images/vv.png'
import img1 from '../images/ss.png'
import img2 from '../images/tech.png'
import img3 from '../images/1.png'
import img4 from '../images/1x1_Teenagers_doing_yoga_outside_.png'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './style.css';

// import required modules
import { Navigation, Mousewheel, Keyboard } from 'swiper/modules';

export default function App() {
  return (
    <Swiper
      cssMode={true}
      navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
      mousewheel={true}
      keyboard={true}
      modules={[Navigation, Mousewheel, Keyboard]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="relative w-full h-[100vh]">
        <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
          <img
            src={img3}
            alt="first Slide"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div className="relative z-10 flex flex-col items-start justify-center h-full p-8 text-white">
            <div className='sm:w-[30rem] sm:ml-20'>
              <h1 className="text-2xl md:text-2xl">The Inyxia Approach to Learning, Rehabilitation, and PTSD Support
              </h1>
              {/* <p className="sm:text-xl mt-4">Our noval digital therapies are fully impressive worlds that offer patients scientifically rigorous and emotionally engaging experience to enhance their nuerorehabilitation </p> */}
              {/* <button className='transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-100 duration-300  font-medium px-10 py-3 border border-white mt-10'>FIND OUT MORE</button> */}
            </div>
          </div>
        </div>
      </SwiperSlide>


      <SwiperSlide>
        <div className="relative w-full h-[100vh]">
        <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
          <img
            src={img4}
            alt="Third Slide"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />

          <div className="relative z-10 flex flex-col items-start justify-center h-full p-8 text-white">
            <div className='sm:w-[30rem] sm:ml-20'>
              {/* <p>MINDPOD</p> */}
              <h1 className="text-2xl sm:text-4xl">Personalized Learning Paths</h1>
              <p className="sm:text-xl mt-4">Every child’s learning journey at Inyxia begins with an in-depth assessment to identify their cognitive
                style. Based on this assessment, we create a personalized learning path that is designed to optimize
                their strengths and address their challenges. This tailored approach ensures that learning and
                rehabilitation is not only effective but also enjoyable and engaging.
              </p>
              {/* <button className='transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-100 duration-300 
            font-medium px-10 py-3 border border-white mt-10'>FIND OUT MORE</button> */}
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="relative w-full h-[100vh]">
          <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
          <img
            src={img}
            alt="Third Slide"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div className="relative z-10 flex flex-col items-start justify-center h-full p-8 text-white">
            <div className='sm:w-[30rem] sm:ml-20 sm:mt-0 mt-14'>
              <h1 className="text-2xl sm:text-4xl">The Resilience Method: Building Mental Strength and Overcoming Trauma</h1>
              <p className="sm:text-xl mt-4">In addition to academic success, we place a strong emphasis on developing mental resilience and
                supporting children who have experienced trauma, including those with PTSD (Post-Traumatic Stress
                Disorder). Our unique Resilience Method incorporates cutting-edge Virtual Reality (VR) and
                Augmented Reality (AR) technologies to create immersive experiences that help children build and
                strengthen their mental resilience and process traumatic memories.</p>
              {/* <button className='transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-100 duration-300  font-medium px-10 py-3 border border-white mt-10'>FIND OUT MORE</button> */}
            </div>
          </div>
        </div>
      </SwiperSlide>


      <SwiperSlide>
        <div className="relative w-full h-[100vh]">
          <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
          <img
            src={img1}
            alt="Third Slide"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div className="relative z-10 flex flex-col items-start justify-center h-full p-8 text-white">
            <div className='sm:w-[30rem] sm:ml-20'>
              <h1 className="text-2xl sm:text-4xl">Resilience Walls</h1>
              <p className="sm:text-xl mt-4">Using AR, we visualize a child’s mental resilience as “walls” that they can build and strengthen
                through positive thinking exercises and guided visualizations. These exercises are designed to help
                children develop coping mechanisms, manage stress, and build a strong mental foundation.</p>
              {/* <button className='transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-100 duration-300  font-medium px-10 py-3 border border-white mt-10'>FIND OUT MORE</button> */}
            </div>
          </div>
        </div>
      </SwiperSlide>


      <SwiperSlide>
        <div className="relative w-full h-[100vh] ">
          <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
          <img
            src={img2}
            alt="Third Slide"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div className="relative z-10 flex flex-col items-start justify-center h-full p-8 text-white">
            <div className='sm:w-[30rem] sm:ml-20 mt-24'>
              <h1 className="text-xl sm:text-4xl ">Trauma Rehabilitation and PTSD Support</h1>
              <p className="sm:text-xl mt-4">For children who have experienced trauma or who struggle with PTSD, we offer VR experiences that
                gradually expose them to controlled scenarios, helping them to reframe and process these
                experiences in a safe, supportive environment. This method, known as VR Exposure Therapy, has
                been shown to significantly reduce PTSD symptoms by allowing individuals to confront and manage
                traumatic memories in a controlled, gradual manner. By helping children process their trauma, we
                aim to reduce the intensity of their emotional responses and improve their overall mental well-being.</p>
              {/* <button className='transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-100 duration-300  font-medium px-10 py-3 border border-white sm:mt-10'>FIND OUT MORE</button> */}
            </div>
          </div>
        </div>
      </SwiperSlide>




      {/* Navigation buttons */}
      <div className="swiper-button-prev text-white"></div>
      <div className="swiper-button-next text-white"></div>
    </Swiper>
  );
}
