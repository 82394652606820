import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './home/Home';
import Homevideo from './homevideo/Homevideo';
import Hometext from './hometext/Hometext';
import Homeslider from './homeslider/Homeslider'
import Homesection from './homesection/Homesection';
import Slider from './slider/Slider';
import Secondvideo from './secondvideo/Secondvideo';
import Footer from './footer/Footer';
import Navbar from './navbar/Navbar';
import Contact from './contact/Contact'
import Career from './career/Careers'
import Clinical from './clinicalpipline/Clinical';
import Autoslider from './autoslider/Autoslider'
import Dualnature from './learning/Dualnature';
import Advancetechnology from './advancetechnology/Advancetechnology'
import About from './about/Aboutus';
import Privacy from './privacy/privacy'
import Location from './location/Location'


function App() {
  return (
    <div className='font-sans'>
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/homevideo" element={<Homevideo />} />
      <Route path="/hometext" element={<Hometext />} />
      <Route path="/homeslider" element={<Homeslider />} />
      <Route path="/homesection" element={<Homesection />} />
      <Route path="/slider" element={<Slider />} />
      <Route path="/secondvideo" element={<Secondvideo />} />
      <Route path="/footer" element={<Footer />} />
      <Route path="/navbar" element={<Navbar />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/careers" element={<Career />} />
      <Route path="/clinical-pipline" element={<Clinical />} />
      <Route path="/autoslider" element={<Autoslider />} />
      <Route path="/dual-nature-of-learning" element={<Dualnature />} />
      <Route path="/advance-technology" element={<Advancetechnology />} />
      <Route path="/about" element={<About />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/location" element={<Location />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
