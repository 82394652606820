import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import bgImg from '../images/11.png';

const Homesection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current);
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    // return () => {
    //   if (ref.current) {
    //     observer.unobserve(ref.current);
    //   }
    // };
  }, []);

  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  return (
    <div>
      <div
        className='sm:h-[50vh] h-[75vh] relative'
        ref={ref}
        style={{ backgroundImage: `url(${bgImg})`, backgroundPosition: 'center 10%', backgroundSize: 'cover' }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className='flex justify-center items-center h-full relative z-10'>
          <div className='sm:w-[60rem] w-[20rem]'>
            <motion.h2
              className='text-2xl text-center text-white my-10'
              variants={fadeInUp}
              initial='hidden'
              animate={isVisible ? 'visible' : 'hidden'}
            >
              Are you ready to give your child an education that nurtures both their mind and their resilience? Are
              you a tech company interested in partnering with us to push the boundaries of AI, VR, and AR in
              education and mental health?
              <p className='mt-3'>
                Join us at Inyxia School of Thought and be part of the future of
                learning and mental resilience.
              </p>
            </motion.h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homesection;
