import React from 'react'
import { motion } from 'framer-motion';
import Img from '../images/1.png';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';

const Aboutus = () => {

    const handleScrollToText = () => {
        const textSection = document.getElementById('text');
        if (textSection) {
            textSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Framer Motion variants for text animation
    const textVariant = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
                ease: 'easeInOut'
            }
        }
    };

    const contentVariant = {
        hidden: { opacity: 0, y: 30 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2,
                duration: 0.8,
                ease: 'easeInOut'
            }
        })
    };


    return (
        <div>
            <div className="bg-no-repeat bg-cover bg-center relative"
                style={{ backgroundImage: `url(${Img})`, height: '75vh' }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className='relative z-10'>
                <Navbar />

                {/* Animated heading text on the image */}
                <motion.h1
                    className='text-white sm:text-4xl text-2xl text-center p-4 sm:px-20 sm:mt-40 mt-10'
                    variants={textVariant}
                    initial="hidden"
                    animate="visible"
                >

                    Inyxia School of Thought: Revolutionizing Education, Mental Resilience, and PTSD Support
                </motion.h1>

                {/* Arrow image at the bottom of the background image */}
                <div
                    className="absolute border top-[64vh] border-white rounded-full py-2 px-1 left-1/2 transform -translate-x-1/2 z-20 cursor-pointer"
                    onClick={handleScrollToText}
                >
                    <img className="w-10 h-8 animate-bounce" src="/arrow.png" alt="downarrow" />
                </div>


                </div>
            </div>

            <div className='flex justify-center items-center flex-col'>
                <div id="text" className='sm:w-[70rem] mx-6'>
                    <motion.div
                        className="mt-28"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium'>About Us
                        </h1>
                        <p className='mt-6'>
                            Inyxia School of Thought was founded on the belief that education and rehabilitation should be as
                            unique as the individual. By harnessing the power of advanced technologies and understanding the
                            diverse ways children learn, we’ve created a platform that not only educates, rehabilitates but also
                            empowers children to be resilient, creative, and adaptive to the challenges of tomorrow. Our
                            commitment to supporting children with PTSD through innovative VR therapy underscores our
                            dedication to the holistic well-being of every child.   </p>
                    </motion.div>

                    <motion.div
                        className="mt-6"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium'>The Minds Behind Inyxia
                        </h1>
                        <p className='mt-6'>
                            Wayne Abdullah and Waheed Zam are the visionary co-founders of Inyxia, each bringing distinct
                            expertise and a shared commitment to revolutionizing the healthcare sector, particularly in mental
                            health care. Wayne’s background in computer engineering, business,   Wayne’s background in computer engineering, business administration, health and
                            wellbeing, and Waheed's expertise in business, the entertainment industry, and studies in
                            philosophy, anthropology, and metaphysics complement each other perfectly, forming a formidable
                            leadership team. Together, they harness cutting-edge AI and digital technologies to create patient-centered solutions that enhance healthcare accessibility, effectiveness, and patient outcomes
                            globally. Their collaborative leadership at Inyxia is characterized by innovation, strategic insight, and a
                            deep dedication to improving the well-being of individuals through transformative healthcare
                            innovations  </p>
                    </motion.div>


                    <motion.div
                        className="mt-6"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium'>Why Choose Inyxia?
                        </h1>
                        <p className='mt-6 mb-10'>
                            Comprehensive Education and PTSD Support: We go beyond academics to provide a holistic learning
                            experience that includes mental resilience training, trauma rehabilitation, and cognitive
                            rehabilitation, especially for children dealing with PTSD.
                            Cutting-Edge Technology: By integrating AI, VR, and AR, we offer a unique educational and
                            rehabilitation environment that adapts to the needs of each child, making learning and recovery
                            engaging and effective.
                            Personalized Support: Every child at Inyxia receives a tailored learning plan that aligns with their
                            cognitive style, ensuring they are both challenged and supported.
                            Empowering Future Innovators: Our approach not only helps children succeed academically but also
                            prepares them to be resilient, creative thinkers capable of thriving in a dynamic, ever-changing
                            world.  </p>
                    </motion.div>


                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Aboutus