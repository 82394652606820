import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Img from '../images/3.png';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import { useLocation } from 'react-router-dom';

const Dualnature = () => {

    const handleScrollToText = () => {
        const textSection = document.getElementById('text');
        if (textSection) {
            textSection.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    // Framer Motion variants for text animation
    const textVariant = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
                ease: 'easeInOut'
            }
        }
    };

    const contentVariant = {
        hidden: { opacity: 0, y: 30 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2,
                duration: 0.8,
                ease: 'easeInOut'
            }
        })
    };

    return (
        <div>
            <div className="bg-no-repeat bg-cover bg-center relative"
                style={{ backgroundImage: `url(${Img})`, backgroundPosition: 'center 30%', height: '75vh' }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className='relative z-10'>
                    <Navbar />

                    {/* Animated heading text on the image */}
                    <motion.h1
                        className='text-white sm:text-4xl text-2xl text-center p-4 sm:px-20 sm:mt-40 mt-10'
                        variants={textVariant}
                        initial="hidden"
                        animate="visible"
                    >
                        Inyxia School of Thought: Revolutionizing Education, Mental Resilience, and PTSD Support
                    </motion.h1>

                    {/* Arrow image at the bottom of the background image */}
                    <div
                        className="absolute top-[64vh] border border-white rounded-full py-2 px-1 left-1/2 transform -translate-x-1/2 z-20 cursor-pointer"
                        onClick={handleScrollToText}
                    >
                        <img className="w-10 h-8 animate-bounce" src="/arrow.png" alt="downarrow" />
                    </div>
                </div>
            </div>




            <div id='ai' className='flex justify-center items-center flex-col'>
                <div id="text" className='sm:w-[70rem]'>
                    <motion.div

                        className="mt-28"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium sm:ml-0 ml-3'>Artificial Intelligence (AI)</h1>

                    </motion.div>


                    <motion.div
                        className="mt-6 ml-10"
                        custom={1}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <ul className='list-disc'>
                            <li className='my-6'>
                                <strong> AI-Powered Personal Coach:</strong>  Our AI-driven coach provides daily motivational support,
                                answers questions, and offers personalized advice based on each child’s progress and needs.
                                It continuously monitors learning patterns to adjust content and exercises, ensuring that
                                every child stays on track and motivated.
                            </li>
                            <li>
                                <strong>Smart Data Analytics:</strong>   Using AI, we analyse learning data to gain insights into each child’s
                                cognitive patterns, helping us tailor educational experiences even further.

                            </li>
                        </ul>


                    </motion.div>

                    <div id='ar'>
                        <motion.div

                            className="mt-10"
                            custom={0}
                            variants={contentVariant}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                        >
                            <h1 className='text-2xl font-medium sm:ml-0 ml-3'>Augmented Reality (AR):
                            </h1>

                        </motion.div>
                    </div>
                    <motion.div
                        className="mt-6 ml-10"
                        custom={1}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <ul className='list-disc'>
                            <li className='my-6'>
                                <strong>Interactive Exercises:</strong>     Our AR tools bring abstract concepts to life, allowing children to
                                interact with visual representations of their thoughts and emotions. These exercises help
                                children better understand themselves and their learning processes, enhancing both
                                cognitive and emotional development.

                            </li>
                            <li>
                                <strong> Resilience Visualization:</strong>    AR allows children to see their progress in real-time as they build
                                and strengthen their Resilience Walls, making their growth tangible and motivating.
                                healing and recovery.


                            </li>
                        </ul>


                    </motion.div>



                    <div  id='vr'>
                    <motion.div
                       
                        className="mt-10"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium sm:ml-0 ml-3'>Virtual Reality (VR)
                        </h1>

                    </motion.div>
                    </div>
                    <motion.div
                        className="mt-6 ml-10"
                        custom={1}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <ul className='list-disc mb-20'>
                            <li className='my-6'>
                                <strong>Immersive Learning Environments:</strong>   Inyxia’s VR modules create fully immersive
                                environments where children can engage in guided visualizations, explore virtual
                                classrooms, and practice mental resilience techniques. These experiences are designed to
                                make learning and personal growth interactive and deeply impactful.
                            </li>
                            <li>
                                <strong>PTSD and Trauma Support:</strong>     Our VR environments are also designed to help children with
                                PTSD by providing a safe space where they can gradually confront and process their
                                traumatic memories. This controlled exposure helps reduce anxiety and fear, promoting
                                healing and recovery.


                            </li>
                        </ul>


                    </motion.div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Dualnature;
