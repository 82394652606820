import React from 'react'
import { motion } from 'framer-motion';
import Img from '../images/17.png';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';

const Aboutus = () => {

    const handleScrollToText = () => {
        const textSection = document.getElementById('text');
        if (textSection) {
            textSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Framer Motion variants for text animation
    const textVariant = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
                ease: 'easeInOut'
            }
        }
    };

    const contentVariant = {
        hidden: { opacity: 0, y: 30 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2,
                duration: 0.8,
                ease: 'easeInOut'
            }
        })
    };


    return (
        <div>
            <div className="bg-no-repeat bg-cover bg-center relative"
                style={{ backgroundImage: `url(${Img})`, backgroundPosition: 'center 30%', height: '75vh' }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className='relative z-10'>
                <Navbar />

                {/* Animated heading text on the image */}
                <motion.h1
                    className='text-white sm:text-4xl text-2xl text-center p-4 sm:px-20 sm:mt-40 mt-10'
                    variants={textVariant}
                    initial="hidden"
                    animate="visible"
                >

                    Inyxia School of Thought: Revolutionizing Education, Mental Resilience, and PTSD Support
                </motion.h1>

                {/* Arrow image at the bottom of the background image */}
                <div
                    className="absolute border top-[64vh] border-white rounded-full py-2 px-1 left-1/2 transform -translate-x-1/2 z-20 cursor-pointer"
                    onClick={handleScrollToText}
                >
                    <img className="w-10 h-8 animate-bounce" src="/arrow.png" alt="downarrow" />
                </div>
                </div>
            </div>

            <div className='flex justify-center items-center flex-col'>
                <div id="text" className='sm:w-[70rem] mx-6'>
                    <motion.div
                        className="mt-28"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium'>Privacy Policy
                        </h1>

                        <p className='mt-6'>
                            Welcome to Inyxia. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you use our website, inyxia.com (the "Website"). By using our Website, you agree to the terms of this Privacy Policy.   </p>
                    </motion.div>

                    <motion.div
                        className="mt-6"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium'>Information We Collect
                        </h1>
                        <p className='my-3'>We may collect various types of information, including but not limited to:</p>
                        <ul className='list-decimal'>
                            <li> <strong> Personal Information:</strong> When you register on our Website, subscribe to our newsletter, or use our services, we may collect personal information such as your name, email address, phone number, and payment details.</li>

                            <li className='my-4'> <strong>  Usage Data: </strong> We may collect information about how you interact with our Website, including your IP address, browser type, pages visited, time spent on pages, and other usage statistics.</li>

                            <li>  <strong> Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to enhance your experience on our Website, gather analytical data, and serve personalized content. You can manage your cookie preferences through your browser settings.</li>
                        </ul>
                    </motion.div>



                    <motion.div
                        className="mt-6"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium'>How We Use Your Information
                        </h1>
                        <p className='my-3'>We use the information we collect for the following purposes:</p>
                        <ul className='list-decimal'>
                            <li> <strong>To Provide and Improve Our Services:</strong> We use your information to operate, maintain, and improve our Website and the services we offer.</li>

                            <li className='my-4'> <strong>To Communicate with You:</strong>  We may use your contact information to send you updates, newsletters, and other communications related to our services. You can opt out of receiving these communications at any time.</li>

                            <li>  <strong>To Process Transactions:</strong>  We use your payment information to process transactions and deliver the products or services you have requested.</li>
                            <li className='my-4'>  <strong>To Ensure Security:</strong> We may use your information to protect the security and integrity of our Website and our users.</li>
                            <li className='my-4'>  <strong>For Legal Compliance:</strong> We may use your information to comply with applicable laws, regulations, and legal processes.</li>
                        </ul>
                    </motion.div>


                    <motion.div
                        className="mt-6"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium'>Security Measures
                        </h1>
                        <p className='mt-6 mb-10'>
                            We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security. </p>
                    </motion.div>

                    <motion.div
                        className="mt-6"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium'>Changes to This Privacy Policy
                        </h1>
                        <p className='mt-6 mb-10'>
                            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our Website and updating the "Last Updated" date at the top. </p>
                    </motion.div>

                    <motion.div
                        className="mb-6"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium'>Contact Us
                        </h1>
                        <p className='mt-6 mb-10'>
                            If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at: </p>
                        <p className='font-bold my-3'>Inyxia</p>
                        <p>support@inyxia.com</p>
                        <p className='my-2'><span className='font-semibold'> Address:</span> Office 3F..56, Majid
                            Sultan Building, Dubai, U.A.E</p>
                    </motion.div>


                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Aboutus