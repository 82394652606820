import React from 'react';
import './style.css'; // Import a custom CSS file for the animation
import Navbar from '../navbar/Navbar';

import video from '../images/homevideo.mp4'



const Homevideo = () => {
    

    // Function to handle smooth scrolling
    const handleScrollToText = () => {
        const textSection = document.getElementById('text');
        if (textSection) {
            textSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <div className="relative w-full h-screen">
                <div className="relative z-50">
                    <Navbar />
                </div>

                {/* Video Background */}
                <video
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    autoPlay
                    loop
                    muted
                    playsInline
                    disablePictureInPicture
                    controlsList="nodownload nofullscreen noremoteplayback"
                    style={{ pointerEvents: 'none' }}
                >
                    <source src={video} type="video/mp4" />
                </video>

                {/* Dark Overlay */}
                <div className="absolute inset-0 bg-black opacity-10 z-10"></div>

                {/* Text Content */}
                <div className="relative z-20 flex flex-col items-center justify-center mt-32 sm:mt-40 ">
                    <div className='sm:w-[50rem] '>
                        <h1 className="text-white text-2xl md:text-6xl text-center">
                            Welcome to Inyxia School of Thought

                        </h1>
                        <p className="sm:mx-0 mx-6 mt-5 text-white">
                    
                        The Inyxia School of Thought is a non-profit program dedicated to transforming the way children learn. 
                        At Inyxia School of Thought, we are dedicated to transforming the way children learn, develop
                            mental resilience, and recover from traumatic experiences. Our approach is rooted in understanding
                            the diverse cognitive styles of learners and leveraging the latest advancements in technology to
                            provide a personalized, immersive, supportive educational and rehabilitation experience.
                        </p>
                        <h3 className='text-xl text-white sm:mx-0 mx-6  mt-6'>Inyxia School of Thought: Revolutionizing Education, Mental Resilience, and PTSD Support
                        </h3>
                    </div>
                </div>

                {/* Arrow image at the bottom with animation */}
                <div
                    className="absolute border border-white rounded-full py-2 px-1 bottom-10 left-1/2 transform -translate-x-1/2 z-20 cursor-pointer"
                    onClick={handleScrollToText} // Handle click for smooth scrolling
                >
                    <img className="w-10 h-8 animate-bounce" src="/arrow.png" alt="downarrow" />
                </div>
            </div>

        </div>
    );
};

export default Homevideo;
