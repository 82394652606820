import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import img from '../images/home1.jpg'
import img1 from '../images/home2.jpg'
import img2 from '../images/home3.jpg'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './slider.css';

// import required modules
import { Navigation, Mousewheel, Keyboard } from 'swiper/modules';

export default function App() {
  return (
    <Swiper
      cssMode={true}
      navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
      mousewheel={true}
      keyboard={true}
      modules={[Navigation, Mousewheel, Keyboard]}
      className="mySwiper"
    >
      <SwiperSlide>
      <div className="relative w-full sm:h-[100vh] h-[57vh]">
          <img
            src={img}
            alt="Third Slide"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div className="relative z-10  flex justify-center items-center flex-col h-full text-center   text-white">
            <h1 className="text-4xl md:text-6xl ">Meet Simon</h1>
            <p className="text-xl mt-4">"When I got up a level , I can see my efforts is paying off"</p>
            <button className='font-medium px-6 py-3 hover:border hover:border-white  hover:bg-white hover:text-sky-600 bg-sky-700 mt-10'>WATCH FILM</button>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="relative w-full sm:h-[100vh] h-[57vh]">
          <img
            src={img1}
            alt="Third Slide"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-4xl md:text-6xl ">Meet Emily</h1>
          <p className="text-xl mt-4">"When I got up a level , I can see my efforts is paying off"</p>
          <button className='font-medium px-6 py-3 hover:border hover:border-white  hover:bg-white hover:text-sky-600 bg-sky-700 mt-10'>WATCH FILM</button>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative w-full sm:h-[100vh] h-[57vh]">
          <img
            src={img2}
            alt="Third Slide"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div className="relative z-10 flex flex-col items-center justify-center h-full  text-white">
          <h1 className="text-4xl md:text-6xl ">Meet John</h1>
          <p className="text-xl mt-4">"When I got up a level , I can see my efforts is paying off"</p>
          <button className='font-medium px-6 py-3 hover:border hover:border-white  hover:bg-white hover:text-sky-600 bg-sky-700 mt-10'>WATCH FILM</button>
          </div>
        </div>
      </SwiperSlide>
      
      {/* Navigation buttons */}
      <div className="swiper-button-prev text-white"></div>
      <div className="swiper-button-next text-white"></div>
    </Swiper>
  );
}
