import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img from '../images/card1.jpg';
import img1 from '../images/card2.jpg';
import img2 from '../images/card3.jpg';

const Hometext = () => {
   
    const { ref: imgRef1, inView: imgInView1 } = useInView({ triggerOnce: true, threshold: 0.1 });
    const { ref: imgRef2, inView: imgInView2 } = useInView({ triggerOnce: true, threshold: 0.1 });
    const { ref: imgRef3, inView: imgInView3 } = useInView({ triggerOnce: true, threshold: 0.1 });

    return (
        <div>
           

            <div className='grid sm:grid-cols-3 grid-cols-1'>
                <motion.div
                    ref={imgRef1}
                    initial={{ opacity: 0, y: 50 }}
                    animate={imgInView1 ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.8 }}
                    className="bg-no-repeat bg-cover bg-center relative"
                    style={{ backgroundImage: `url(${img})`, height: '60vh' }}
                >
                    <div className='flex flex-col py-24 justify-between items-center '>
                        <h2 className='text-white text-2xl font-medium'>Medicine</h2>
                        <p className='leading-loose text-white text-center sm:px-20 px-6'>
                            Next-generation evidence-based, protocolised therapies designed for true restoration of motor, cognitive and cardiovascular function following neural injuries, degeneration & ageing
                        </p>
                        <button className='text-white px-10 py-2 bg-sky-700 mt-3 hover:bg-white hover:text-sky-500 '>
                            FIND OUT MORE
                        </button>
                    </div>
                </motion.div>

                <motion.div
                    ref={imgRef2}
                    initial={{ opacity: 0, y: 50 }}
                    animate={imgInView2 ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="bg-no-repeat bg-cover bg-center relative"
                    style={{ backgroundImage: `url(${img1})`, height: '60vh' }}
                >
                    <div className='flex flex-col py-24 justify-between items-center '>
                        <h2 className='text-white text-2xl font-medium'>Care</h2>
                        <p className='leading-loose text-white text-center sm:px-20 px-6'>
                        Therapies & technologies that enable clinicians to maximise the delivery of motor and cognitive neurorehabilitation across the continuum of care
                        </p>
                        <button className=' text-white px-10 py-2 bg-sky-700 mt-10 hover:bg-white hover:text-sky-500'>
                            FIND OUT MORE
                        </button>
                    </div>
                </motion.div>

                <motion.div
                    ref={imgRef3}
                    initial={{ opacity: 0, y: 50 }}
                    animate={imgInView3 ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    className="bg-no-repeat bg-cover bg-center relative"
                    style={{ backgroundImage: `url(${img2})`, height: '60vh' }}
                >
                    <div className='flex flex-col py-24 justify-between items-center '>
                        <h2 className='text-white text-2xl font-medium'>Monitor</h2>
                        <p className='leading-loose text-white text-center sm:px-20 px-6'>
                        Diversified digital assessments for high fidelity, reliable quantification of motor and cognitive recovery in patients
                        </p>
                        <button className=' text-white px-10 py-2 bg-sky-700 mt-10 hover:bg-white hover:text-sky-500'>
                            FIND OUT MORE
                        </button>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default Hometext;
