import React from 'react'
import bgImg from '../images/2.png';
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';

const Location = () => {
    return (
        <div>
            <div className="bg-no-repeat bg-cover bg-center relative "
                style={{ backgroundImage: `url(${bgImg})`, backgroundPosition: 'center 20%', height: '75vh' }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className='relative z-10'>
                <div>
                    <Navbar />
                </div>
                <h1 className='text-white sm:text-4xl text-2xl text-center p-4 sm:px-20 sm:mt-10'>
                    Inyxia School of Thought: Revolutionizing Education, Mental Resilience, and PTSD Support
                </h1>
                </div>

            </div>
            <div className='flex justify-center items-center my-10 rounded-xl'>
                <iframe className='w-[70%] h-[70vh] allowFullScreen' title='video' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.199456775062!2d55.28964997521371!3d25.26387497766809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f435572a10d01%3A0x859abd8d36dff1dd!2sMajid%20Bin%20Sultan%20Building!5e0!3m2!1sen!2s!4v1725357211282!5m2!1sen!2s" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <Footer />
        </div>
    )
}

export default Location