import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Img from '../images/8.png';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import { useLocation } from 'react-router-dom';

const Dualnature = () => {
    const location = useLocation();

    // Function to adjust the scroll with an offset
    const scrollWithOffset = (element) => {
        const yOffset = window.innerWidth < 768 ? -80 : -120; // Adjust the offset for mobile and desktop
        const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
    };

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                scrollWithOffset(element);
            }
        }
    }, [location]);

    const handleScrollToText = () => {
        const textSection = document.getElementById('text');
        if (textSection) {
            scrollWithOffset(textSection);
        }
    };

    // Framer Motion variants for text animation
    const textVariant = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
                ease: 'easeInOut'
            }
        }
    };

    const contentVariant = {
        hidden: { opacity: 0, y: 30 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2,
                duration: 0.8,
                ease: 'easeInOut'
            }
        })
    };

    return (
        <div>
            <div className="bg-no-repeat bg-cover bg-center relative"
                style={{ backgroundImage: `url(${Img})`, backgroundPosition: 'center 30%', height: '75vh' }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className='relative z-10'>
                    <Navbar />

                    {/* Animated heading text on the image */}
                    <motion.h1
                        className='text-white sm:text-4xl text-2xl text-center p-4 sm:px-20 sm:mt-40 mt-10'
                        variants={textVariant}
                        initial="hidden"
                        animate="visible"
                    >
                        Inyxia School of Thought: Revolutionizing Education, Mental Resilience, and PTSD Support
                    </motion.h1>

                    {/* Arrow image at the bottom of the background image */}
                    <div
                        className="absolute border top-[64vh] border-white rounded-full py-2 px-1 left-1/2 transform -translate-x-1/2 z-20 cursor-pointer"
                        onClick={handleScrollToText}
                    >
                        <img className="w-10 h-8 animate-bounce" src="/arrow.png" alt="downarrow" />
                    </div>
                </div>
            </div>

            <div className='flex justify-center items-center flex-col'>
                <div id="text" className='sm:w-[70rem] mx-6'>
                    <motion.div
                        className="mt-28"
                        custom={0}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h1 className='text-2xl font-medium'>Understanding the Absorptive and Explorative Minds</h1>
                        <p className='mt-6'>Children learn in different ways, and at Inyxia, we recognize that each child has a unique cognitive
                            style that influences how they absorb and process information. We categorize these into two
                            predominant types:</p>
                    </motion.div>

                    <motion.div
                        className="mt-6"
                        custom={1}
                        variants={contentVariant}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <ul className='list-disc'>
                            <div id='ab' className='mt-2'>
                                <li className='my-10'>
                                    <strong>The Absorptive Mind</strong> These children excel in environments where knowledge is
                                    systematically presented. They thrive on logical reasoning, clear guidelines, and structured
                                    learning. The Absorptive Mind is highly receptive to traditional educational methods but
                                    benefits from opportunities to expand creative and independent thinking.
                                </li>
                            </div>
                            <div id='ex' className='my-10'></div>
                            <li>
                                <strong>The Explorative Mind</strong> In contrast, children with an Explorative Mind are driven by curiosity
                                and a desire for hands-on learning. They prefer discovering knowledge independently and
                                often engage in deep, abstract thought. While they may struggle with conventional learning
                                environments, they flourish in settings that encourage experimentation and creativity. Our
                                approach helps them channel their natural curiosity productively.
                            </li>
                        </ul>

                        <p className='mt-10 mb-20 font-semibold'>At Inyxia, we tailor our educational and rehabilitation methods to suit both the Absorptive and
                            Explorative Minds, ensuring that every child receives the support and challenges they need to thrive.</p>
                    </motion.div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Dualnature;
