import React, { useState } from 'react';
import bgImg from '../images/4.png';
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import { Link } from 'react-router-dom';

const Contact = () => {
    // Manage form state
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        message: '',
    });

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleEmailSubmit = (e) => {
        e.preventDefault();

        // Build the mailto link with the form values
        const mailtoLink = `mailto:support@inyxia.com?subject=Inquiry from ${formData.name}&body=Name: ${formData.name}%0D%0ACompany: ${formData.company}%0D%0AEmail: ${formData.email}%0D%0APhone: ${formData.phone}%0D%0AMessage: ${formData.message}`;

        // Trigger the email client
        window.location.href = mailtoLink;
    };

    return (
        <div>
            <div
                className="bg-no-repeat bg-cover relative w-full"
                style={{ backgroundImage: `url(${bgImg})`, backgroundPosition: 'center 40%', height: '75vh' }}
            >
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative z-10">
                    <Navbar />
                    <h1 className="text-white sm:text-4xl text-2xl text-center p-4 sm:px-20 sm:mt-10">
                        Our goal is to better connect humanity to themselves, each other, and the world around us. To find out more, connect with us.
                    </h1>

                    {/* Arrow image at the bottom of the background image */}
                    <div className="absolute border border-white rounded-full py-2 px-1 left-1/2 transform -translate-x-1/2">
                        <img className="w-10 h-8 animate-bounce" src="/arrow.png" alt="downarrow" />
                    </div>
                </div>
            </div>

            <div className="grid sm:grid-cols-2">
                <div className="sm:ml-28 mx-4">
                    <div className="my-8">
                        <h3 className="text-2xl">Visit Us</h3>
                        <p className="my-2 text-gray-600">Inyxia</p>
                        <p className="text-gray-600">Office 3F..56, Majid Sultan Building, Dubai,</p>
                        <p className="text-gray-600">U.A.E</p>
                    </div>

                    <div className="mt-5">
                        <Link to="/location">
                            <button className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 hover:shadow-md duration-300 px-24 py-4 border border-black text-gray-600 font-semibold">
                                LOCATION
                            </button>
                        </Link>
                    </div>

                    <div className="my-8">
                        <h3 className="text-2xl">Call Us</h3>
                        <p className="my-2 text-gray-600">ALL ENQUIRES</p>
                        <p className="my-2 text-gray-600">+971585405911</p>
                    </div>

                    <div className="my-8">
                        <h3 className="text-2xl mb-3">Customer Support</h3>
                        <p>PLEASE CONTACT:</p>
                        <p className="hover:underline mt-1">support@inyxia.com</p>
                        <p className="my-2">UAE: <span className="hover:underline text-sky-800">+971585405911</span></p>
                    </div>

                    <div>
                        <p>Monday – Friday</p>
                        <p>9am (GMT +4) to 5pm Gulf Standard Time</p>
                    </div>
                </div>

                <div className="sm:mr-10 mx-4">
                    <div>
                        <h3 className="text-2xl my-10">Contact Us</h3>
                        <form onSubmit={handleEmailSubmit}>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="bg-gray-50 border border-black text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-3 mt-4"
                                placeholder="Name"
                                required
                            />

                            <input
                                type="text"
                                name="company"
                                value={formData.company}
                                onChange={handleChange}
                                className="bg-gray-50 border border-black text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-3 mt-4"
                                placeholder="Company"
                                required
                            />

                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="bg-gray-50 border border-black text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-3 mt-4"
                                placeholder="Email"
                                required
                            />

                            <input
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className="bg-gray-50 border border-black text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-3 mt-4"
                                placeholder="Phone"
                                required
                            />

                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="12"
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-black mt-4 focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Message"
                                required
                            />

                            <div className="flex mt-7">
                                <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    value=""
                                    className="w-4 mr-3 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                    required
                                />
                                <p>I agree with the privacy policy and that MindMaze can store my information solely for the purpose of contacting me</p>
                            </div>

                            <div className="my-8">
                                <button
                                    type="submit"
                                    className="transition ease-in-out delay-150 hover:shadow-md hover:-translate-y-1 hover:scale-100 duration-300 border border-black px-12 py-3.5"
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Contact;
