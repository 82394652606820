import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Img from '../images/Back-2.jpg'

const Clinical = () => {
    return (
        <div className='bg-white'>
            <div className="bg-no-repeat bg-cover bg-center relative "
                style={{ backgroundImage: `url(${Img})`, backgroundAttachment: 'fixed', height: '50vh' }}>
                <div>
                    <Navbar />
                </div>
                <div className='sm:px-20 px-5 sm:mt-10 mt-5 text-center'>
                    <p className='text-xl text-white'>Clinical Pipeline</p>
                    <h1 className='text-white sm:text-4xl text-2xl  p-4 '>
                        Our clinical pipeline is the future of neurorestoration
                    </h1>
                </div>

                {/* Arrow image at the bottom of the background image */}
                <div className="absolute bottom-4 border border-white rounded-full py-2 left-1/2 transform -translate-x-1/2">
                    <img className="w-14 h-8 animate-bounce" src="/arrow.png" alt="downarrow" />
                </div>
            </div>

            <div className='flex justify-center items-center'>
                <div className='sm:w-[70rem]'>
                    <div className='my-20 mx-2'>
                        <img src='/mmm.png' alt='clinicalPipeline' />
                    </div>



                </div>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}

export default Clinical