import React from 'react';
import Homevideo from '../homevideo/Homevideo';
import Homeslider from '../homeslider/Homeslider';
import Homesection from '../homesection/Homesection';
import Footer from '../footer/Footer';
import Autoslider from '../autoslider/Autoslider';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
// import video from '../images/InyxiaHomeVideo.mp4';

const Home = () => {
    const { ref: textRef, inView: textInView } = useInView({ triggerOnce: true, threshold: 0.1 });
    // const [showVideo, setShowVideo] = useState(true);  

    // const handleEnterClick = () => {
    //     setShowVideo(false);  
    // };

    return (
        <div className='bg-white'>
            {/* Overlay Video and Enter button */}
            {/* {showVideo && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <video
                        className="absolute top-- left-0 w-full h-full object-cover"
                        autoPlay
                        loop
                        muted
                        playsInline
                        disablePictureInPicture
                        controlsList="nodownload nofullscreen noremoteplayback"
                        style={{ pointerEvents: 'none' }}
                    >
                        <source src={video} type="video/mp4" />
                    </video>
                   
                    <button
                        onClick={handleEnterClick}
                        className='relative mt-[80vh] z-10 px-14 rounded py-3 border  text-white bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% ...'
                    >
                        Enter into the world of school of thought
                    </button>
                </div>
            )} */}

            {/* Content */}
            {/* {!showVideo && ( */}
                <>
                    <div>
                        <Homevideo />
                    </div>

                    <motion.div
                        ref={textRef}
                        initial={{ opacity: 0, y: 50 }}
                        animate={textInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.8 }}
                        className="flex justify-center items-center"
                    >
                        <div id="text" className='sm:w-[45rem] w-[20rem] py-20 flex justify-center items-center flex-col'>
                            <p className='sm:text-4xl text-2xl leading-normal text-center'>
                                Our Philosophy: The Dual Nature of Learning
                            </p>
                            <Link to='/dual-nature-of-learning'>
                                <button className='text-white px-10 py-4 bg-sky-700 mt-8 hover:border hover:border-sky-500 hover:bg-white hover:text-sky-500'>
                                    FIND OUT MORE
                                </button>
                            </Link>
                        </div>
                    </motion.div>

                    <div>
                        <Homeslider />
                    </div>

                    <div className='flex justify-center items-center flex-col'>
                        <div className='sm:w-[60rem] w-[20rem] py-24 text-gray-600 flex justify-center flex-col items-center '>
                            <p className='sm:text-4xl text-center text-2xl'>
                                Leveraging Advanced Technologies
                            </p>
                            <p className='text-center py-4 font-medium'>
                                At Inyxia, we integrate the latest in AI, VR, and AR technologies to create an engaging, adaptive, and supportive learning environment.
                            </p>
                            <Link to='/advance-technology'>
                                <button className='text-white px-10 py-4 bg-sky-700 mt-8 hover:border hover:border-sky-500 hover:bg-white hover:text-sky-500'>
                                    FIND OUT MORE
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div>
                        <Homesection />
                    </div>

                    <div className='mt-10'>
                        <Autoslider />
                    </div>

                    <div className='flex sm:flex-row flex-col justify-center items-center text-white' style={{ backgroundColor: '#5789a9' }}>
                        <h1 className='sm:text-4xl text-2xl px-2 text-center py-16'>BE A PART OF INYXIA</h1>
                        <Link to='/contact'>
                            <button className='bg-white px-12 sm:my-0 my-4 py-3 sm:ml-9' style={{ color: "#5789a9" }}>
                                CONTACT US
                            </button>
                        </Link>
                    </div>

                    <div>
                        <Footer />
                    </div>
                </>
            {/* // )} */}
        </div>
    );
}

export default Home;
