import React from 'react'
import Img from '../images/team.jpg'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';

const Careers = () => {
    return (
        <div className='bg-white'>
            <div className="bg-no-repeat bg-cover bg-center relative "
                style={{ backgroundImage: `url(${Img})`, backgroundAttachment: 'fixed', height: '70vh' }}>
                <div>
                    <Navbar />
                </div>
                <h1 className='text-white sm:text-4xl text-2xl text-center p-4 sm:px-20 sm:mt-40 mt-10'>
                    MindMaze is accelerating humanity’s ability to recover, learn and adapt through advanced neuroscience and digital therapeutics.
                    Become a part of our exciting journey.
                </h1>

                {/* Arrow image at the bottom of the background image */}
                <div className="absolute bottom-4 border border-white rounded-full py-2 left-1/2 transform -translate-x-1/2">
                    <img className="w-14 h-8 animate-bounce" src="/arrow.png" alt="downarrow" />
                </div>
            </div>

            <div className='flex justify-center items-center flex-col'>

                <div className='my-14'>
                    <h1 className='sm:text-4xl text-2xl text-gray-600'>Current vacancies   </h1>
                </div>

                <div className='sm:w-[60rem] w-[20rem]'>
                    <div className=' py-6 sm:flex justify-center'>
                        <div className='px-1 py-1' style={{ border: '1px solid #cccccc', backgroundColor: '#eeeeee' }}>
                            <input className=' p-2.5' style={{ border: '1px solid #cccccc' }} placeholder='filter by title, expertise ' />
                            <button className='p-2.5  text-gray-600' style={{ border: '1px solid #cccccc', backgroundColor: '#eeeeee' }}>Search</button>
                        </div>

                        <div className='w-24 text-center pt-2 text-gray-700' style={{ border: '1px solid #cccccc', backgroundColor: '#eeeeee' }}>
                            Location  <span className='text-[0.7rem]'> ▼</span>
                        </div>
                        <div className='w-32 text-center pt-2 text-gray-700' style={{ border: '1px solid #cccccc', backgroundColor: '#eeeeee' }} >
                            Department <span className='text-[0.7rem]'> ▼</span>
                        </div>

                        <div className='flex  sm:w-[30rem] pl-3 text-center pt-2 text-gray-700' style={{ border: '1px solid #cccccc', backgroundColor: '#eeeeee' }}>
                            <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 mt-1 mr-1  text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <p>
                                Employees can work remotely
                                <FontAwesomeIcon className='ml-2' icon={faEarthAmericas} />
                            </p>

                        </div>
                    </div>
                    <div className='grid grid-cols-3 '>
                        <p className='text-center'>Job title</p>
                        <p className='text-center'>Department</p>
                        <p className='text-center'>Location</p>

                    </div>
                    <hr class="h-px mt-4 bg-black border-0 dark:bg-gray-700" />

                    <div className='grid grid-cols-3 py-3 bg-gray-100 cursor-pointer text-gray-600 font-medium hover:bg-yellow-600' >
                        <p className='text-center'>Logicts & customer support specialist</p>
                        <p className='text-center'>Operations</p>
                        <p className='text-center'>Raleigh , NC</p>

                    </div>
                    <div className='grid grid-cols-3 py-3 bg-gray-200 cursor-pointer text-gray-600 font-medium hover:bg-yellow-600' >

                        <p className='text-center'>Executive Assistant to the CEO </p>
                        <p className='text-center'>CEO office</p>
                        <p className='text-center'>Lausanne, Switzerland</p>
                    </div>




                    {/* <hr class="h-px my-8 bg-black border-0 dark:bg-gray-700" /> */}
                    <div className='text-gray-500  mt-6 font-semibold'>
                        Can’t find the right role? We are always looking for exceptional talents. Please submit your resume to be considered for future positions.
                    </div>
                    <div className='my-6'>
                        <button className='transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-100 duration-300 border border-black py-4 px-5'>SUBMIT YOUR APPLICATION</button>
                    </div>

                </div>

            </div>
            <div>
                <Footer />
            </div>

        </div>
    )
}

export default Careers