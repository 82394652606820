import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './design.css'; 

export default function App() {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768, // Tablet and above
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430, // Mobile and above
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-white flex justify-center items-center">
      <div className="sm:w-[70rem] w-[20rem]  pt-3">
        <Slider {...settings}>
          <div>
            <img className="h-10" src="slide1.PNG" alt="Slide 1" />
          </div>
          <div>
            <img src="slide2.PNG" alt="Slide 2" />
          </div>
          <div>
            <img className="h-20" src="slide3.PNG" alt="Slide 3" />
          </div>
          <div>
            <img src="slide4.PNG" alt="Slide 4" />
          </div>
          <div>
            <img src="slide5.PNG" alt="Slide 5" />
          </div>
          <div>
            <img src="slide6.PNG" alt="Slide 6" />
          </div>
          <div>
            <img src="slide7.PNG" alt="Slide 7" />
          </div>
          <div>
            <img src="slide8.PNG" alt="Slide 8" />
          </div>
        </Slider>
      </div>
    </div>
  );
}
