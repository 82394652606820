import React , {useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn ,faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <div className='bg-gray-800 text-white flex justify-center items-center'>
      <div className='sm:w-[70rem] w-[20rem]'>
        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        <div className='flex justify-between flex-col items-center sm:flex-row'>

          <div className='my-3'> 
            <h2 className=''>Office 3F..56, Majid </h2>
            <p>Sultan Building, Dubai, U.A.E</p>
          </div>

          <div className="flex items-center sm:my-0 my-3 space-x-6 text-white">
            <a href="https://www.facebook.com/inyxiaAI" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} className="h-4" />
            </a>
            <a href="https://x.com/inyxia_ai" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} className="h-4" />
            </a>
            <a href="https://www.linkedin.com/company/inyxia/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedinIn} className="h-4" />
            </a>
            <a href="https://www.youtube.com/@inyxia_ai" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} className="h-4" />
            </a>
            <a href="https://www.instagram.com/inyxia__ai/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="h-4" />
            </a>
        </div>

          <div className='mt-2'>
            <p className='font-semibold mb-1'>
            +971585405911
            </p>
            <p>support@inyxia.com</p>
          </div>


        </div>
        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

        <div className=''>
          <ul className='text-white cursor-pointer grid sm:grid-cols-5 gap-2 grid-cols-2'>
            {/* <li className='font-semibold hover:text-sky-600 '>INSIGHTS</li> */}
            {/* <li className='font-semibold hover:text-sky-600 '> SUPPORT</li> */}
      <Link to='/location'>
            <li className='font-semibold hover:text-sky-600 sm:mb-0 mb-2 '> LOCATION</li>
      
      </Link>
            <Link to='/contact'>
            <li className='font-semibold hover:text-sky-600 '> CONTACT</li> 
            </Link>
            <li className='font-semibold hover:text-sky-600 '>
              <Link to='/about'>
               ABOUT
              </Link>
               </li>

      <Link to='/privacy-policy'>
            <li className='font-semibold hover:text-sky-600 col-span-2 '> PRIVACY POLICY</li>
            </Link>

            {/* <li className='font-semibold hover:text-sky-600 '> CAREERS</li> */}
            {/* <li className='font-semibold hover:text-sky-600 '>TESTINOMIALS </li> */}
          </ul>
        </div>
        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <div className='text-gray-500 text-center pb-10'>
          <p>COPYRIGHT 2024 | <span>TERM & CONDITIONS |</span><span>PERSONAL DATA REQUEST </span></p>
        </div>
      </div>
    </div>
  )
}

export default Footer